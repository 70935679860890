.tracking {
  position: relative;

  h2 {
    margin: 0 0 24px;
  }

  .actions {
    position: absolute;
    top: -2px;
    right: 0;
  }

  .link {
    margin: 12px 0;
  }

  .list {
    max-height: max(calc(65vh - 120px), 460px);
    overflow-y: auto;
    > * {
      margin: 8px 0 0;
    }

    :global {
      .ant-alert {
        margin-bottom: 16px;

        .ant-alert-message {
          text-transform: capitalize;
        }
      }
    }

    .overflow {
      overflow: hidden;
      margin-top: -8px;

      .detail {
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        i {
          height: 100%;
          width: 10px;

          &:before,
          &:after {
            position: absolute;
            content: '';
          }

          &:before {
            border-radius: 50%;
            border: solid 2px #B7B7B7FF;
            background-color: #fff;
            box-sizing: border-box;
            width: 10px;
            height: 10px;
            left: 0;
            top: 4px;
          }

          &:after {
            top: 14px;
            left: 4px;
            width: 2px;
            height: 999%;
            background-color: #B7B7B7FF;
          }

          &.active:before {
            background-color: #339999;
            border-color: #339999;
          }
        }

        span {
          margin-left: 16px;
          padding-bottom: 20px;

          div {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0,0,0,.65);
          }
        }
      }
    }
  }

  :global {
    .ant-alert {
      border: none;
      border-radius: 0;
    }
  }
}
