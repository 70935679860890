.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  height: 32px;
  margin: 24px 0;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .search {
    max-width: 300px;
  }
}

.listsWrapper {
  margin-left: 12px;
}

.subTitle {
  font-size: 14px;
  font-weight: 600;
}

.scrollList {
  overflow-y: auto;
  max-height: 360px;
}
