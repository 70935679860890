@mixin box-style() {
  border: solid 1px rgba(0,0,0,.06);
  border-radius: 6px;
}

@mixin info() {
  @include box-style();
  margin: 24px 24px 16px;
  padding: 0 24px 24px 24px;
}

.company {
  .info {
    @include info();

    display: flex;
    flex-wrap: wrap;
    position: relative;

    h4 {
      &:first-child {
        margin: 0;
      }

      &:not(:first-child) {
        margin: 12px 0 0;
      }
    }

    > *:not(.spin) {
      min-width: 200px;
      max-width: 25%;
      width: 100%;
      box-sizing: border-box;
      margin-top: 24px;

      > * {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:not(:last-child) {
        padding-right: 24px;
      }
    }

    .spin {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,.4);
    }
  }

  :global {
    .ant-pro-table-search-query-filter {
      @include box-style();
      margin: 0 24px;
      max-width: calc(100% - 48px);
    }
  }
}
