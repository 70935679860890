.wrapper,
.leftContent,
.rightContent {
  display: grid;
  grid-gap: 16px 24px;
}

.wrapper {
  grid-template-columns: 2fr minmax(255px, 1fr);

  .leftContent {
    grid-template-columns: 1fr;
  }

  .rightContent {
    grid-template-columns: 1fr;
  }

  .orderType,
  .deliveryInfo,
  .shipper,
  .importer,
  .products,
  .documents {
    border-radius: 6px;
    border: solid 1px rgba(0,0,0,.15);
    padding: 24px;
  }

  .summaryInfo {
    background-color: #fff;
    border-radius: 8px;
  }
  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .steps {
    margin-top: 24px;
  }
  .stepsTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .step {
    margin-bottom: 12px;
  }
  .step p {
    font-size: 14px;
    margin: 4px 0 0;
  }

  .header {
    display: flex;
    flex-wrap: nowrap;
    height: 32px;
    line-height: 32px;
    justify-content: space-between;
    align-items: center;

    > div {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    h2 {
      display: block;
      margin: 0;
    }
  }

  .content {
    position: relative;
  }

  .row {
    margin-top: 12px;
    flex-wrap: wrap;

    > *:first-child:not(:last-child) {
      border-bottom: none;
    }

    > a {
      display: block;
      max-width: 190px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    b {
      display: block;
      width: 100%;
    }

    .col2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;

      > * {
        display: block;

        > * {
          display: block;
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .col3 {
      @extend .col2;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .documents {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .title {
        padding: 12px 0 8px;
      }

      .list {
        padding: 12px 0 24px;

        a {
          display: flex;
          align-items: center;
          height: 22px;
          line-height: 22px;
          width: max-content;

          span {
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            padding: 0;
          }

          svg {
            font-size: 18px;
            color: rgba(0,0,0,.45);
          }
        }
      }
    }
  }
}

.overviewForm {
  :global {
    .ant-form-item-control-input {
      width: unset;
    }
  }
}
