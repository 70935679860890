@import "src/resource/style/variables";

.item {
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: 24px 1fr auto;
  gap: 8px;
  align-items: center;

  color: $neutral-secondary;

  .invoiceNumber {
    color: $primary-color;
  }

  .date {
    font-size: 12px;
  }

  .icon {
    font-size: 24px;
  }

  &:hover {
    background-color: rgba(211, 211, 211, 0.15);
  }
}
