@import "src/index";

.delivery {
  display: grid;
  grid-gap: 24px;
  //grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-template-columns: calc(100% - 24px);

  //&.userGrid {
  //  grid-template-columns: calc(100% - 24px);
  //}

  .trackingShipment { // > *:last-child {}
    @extend .bordered-container;
    overflow: hidden;
  }

  .optionalServices {
    max-width: 560px;

    :global {
      .ant-row {
        display: block;

        .ant-form-item-control-input,
        .ant-select {
          min-width: 100%;
        }
      }
    }
  }

  /*.formVertical {
    margin-top: 0;

    button:not(:last-child) {
      margin-right: 12px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    :global {
      .ant-row {
        display: block;

        .ant-col {
          max-width: none;
          text-align: left;
        }

        .ant-form-item-control-input,
        .ant-select {
          min-width: 100%;
        }
      }
    }
  }*/
}

.rowGrid {
  display: grid !important;
  column-gap: 12px;
  flex-wrap: wrap;
}

.rowTwoGrid {
  @extend .rowGrid;
  grid-template-columns: min-content auto;
}

.rowFourItems {
  display: flex !important;
  column-gap: 12px;
  flex-wrap: wrap;
}
