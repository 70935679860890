.order {
  :global {
    .ant-form-item .ant-form-item-row {
      max-width: 1200px;
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-form-item-control-input {
      width: calc(100% - 128px);
    }

    .ant-form-item-extra {
      position: absolute;
      right: 0;
      top: 0;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-input-number-affix-wrapper {
      width: 100%;
    }
  }
}

.btnCalc {
  :global {
    .ant-form-item-control-input {
      width: calc(100% - 168px);
    }

    .ant-form-item-extra {
      width: 168px;
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.wrapper {
  > *:first-child {
    margin-top: 16px;
  }

  .noLabel {
    margin-left: 200px;

    :global {
      .ant-form-item-control {
        display: grid;
        grid-template-columns: auto 100%;

        .ant-form-item-control-input {
          width: auto;
        }

        .ant-form-item-extra {
          position: relative;
          display: flex;
          align-items: center;
          color: rgba(0,0,0,.88);
        }
      }
    }
  }

  :global {
    .ant-upload-list-item {
      min-width: 170px;
    }

    .ant-switch {
      margin-right: 12px;
    }

    .ant-form-item-label {
      max-width: 200px;
    }

    .ant-form-item-control-input-content {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;

      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .ant-form-item {
      max-width: none;
    }
  }

  &.vertical {
    :global {
      .ant-form-item-label {
        max-width: unset;
      }
    }
  }

  h3 {
    position: relative;
    display: block;
    width: 100%;
    margin: 16px 0 0 0;
    box-sizing: border-box;
    text-transform: capitalize;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 27.5px;
      width: 100%;
      height: 0;
      border-bottom: solid 1px rgba(0,0,0,.06);
    }

    span {
      position: relative;
      display: inline-block;
      background-color: #fff;
      padding: 0 16px;
      margin: 0 0 0 16px;
      height: 56px;
      line-height: 56px;
    }
  }
}

.sizeLWH {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-start;

  > * {
    width: 100%;
  }

  :global {
    .ant-row > .ant-col .ant-form-item-control-input {
      width: calc(100% - 128px);

      .ant-form-item-control-input {
        width: auto;
      }
    }

    .ant-form-item:first-child {
      width: calc(100% - 150px) !important;
    }

    .ant-form-item-control-input-content {
      > *:not(:first-child) {
        margin-left: 24px;
      }
    }
  }
}

.doubleInput {
  :global {
    .ant-form-item-control-input {
      width: calc(70% - 136px);
    }

    .ant-form-item-extra {
      display: flex;
      flex-wrap: nowrap;
      width: calc(30% + 136px);
      padding-left: 8px;

      .ant-form-item-extra {
        display: none;
      }

      .ant-form-item {
        width: calc(100% - 128px);
      }

      .ant-form-item-control-input {
        width: 100%;
      }
    }

    .ant-form-item .ant-form-item,
    .ant-input-number-affix-wrapper {
      width: 100%;
    }
  }
}

.text {
  margin-left: 8px;
  line-height: 32px;
  color: rgba(0,0,0,.45);
  max-width: 120px;
  min-width: 120px;
}

.upload {
  :global {
    .ant-form-item-extra {
      left: 0 !important;
      pointer-events: none;
    }

    .ant-upload-icon {
      svg {
        font-size: 18px;
        margin-top: 2px;
        padding-top: 2px;
      }
    }
  }
}

.input {
  position: relative;
}

.modalTable {
  :global {
    .ant-pro-card-body {
      margin: 0;
      padding: 0;

      .ant-pro-table-list-toolbar-setting-items {
        display: none;
      }

      .ant-pro-core-label-tip {
        flex-wrap: wrap;

        .ant-pro-core-label-tip-subtitle {
          width: 100%;
          margin: 8px 0 0 0;
        }
      }

      table tbody > tr {
        cursor: pointer;
      }
    }
  }

  .filter {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    background-color: rgba(0,0,0,.04);
    border-radius: 4px;
    margin-top: 16px;

    > * {
      cursor: pointer;
      display: flex;
      margin: 2px;
      border-radius: 2px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      padding: 0 8px;

      &:hover,
      &.active {
        background-color: #fff;
      }
    }
  }
}

.inputSelectProduct {
  svg {
    width: auto;
    height: auto;
    padding: 9px 13px;
    transform: translate(0, 2px);
    margin: -3px -12px 0;
  }
}

// New for input select good for package
.productsWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  width: 100%;
  min-height: 32px;
  align-items: center;

  gap: 4px;
  border: solid 1px #d9d9d9;
  border-radius: 6px;

  &.error {
    border-color: #FF4D4F;
  }

  .mainContent {
    padding: 4px 11px;
    display: flex;
    flex-wrap: wrap;
    white-space: normal;
    gap: 4px;
  }

  .addonAfter {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productItem {
    display: flex;
    gap: 6px;
    background: #ededed;
    padding: 2px 6px;
    border-radius: 6px;
  }

  .deleteIcon {
    font-size: 14px;
    cursor: pointer;
    padding: 0 3px;
    border-radius: 3px;

    &:hover {
      background-color: lightgrey;
    }
  }
}
.segment{

  .ant-segmented-item-label {
    height: 40px !important;
  }
  .ant-segmented {

  }
}
.ant-segmented .ant-segmented-item-label {
  height: 40px !important;
}
.emailsTitle::before{
  display: none !important;
}