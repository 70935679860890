@import 'src/resource/style/variables';

.actions {
  margin: 0 -6px;

  svg {
    color: rgba(0,0,0,.45);
    transition: .2s;
  }

  button:hover {
    svg {
      color: rgba(0,0,0,.7);
    }
  }
}

.actionBtn {

}

.table {

}
