.content {
  h1, button {
    margin: 24px 0;
  }

  .footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button, p {
      margin: unset;
    }
  }

  h3 {
    text-transform: none;
  }
}
