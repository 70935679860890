 //TODO can be removed. styles moved into index.scss .form-customized-cols
.form {
  padding: 64px 40px;
  position: relative;

  &.miniTopPadding {
    padding: 24px 40px;
  }

  :global {
    .ant-form-item {
      max-width: 1000px;
    }

    .ant-form-item-label {
      max-width: 180px;
      font-weight: normal;
    }
  }

  &.vertical {
    :global {
      .ant-form-item-label {
        max-width: unset;
      }
    }
  }

  .spin {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,.4);
  }

  //*:not(:first-child) {
  //  > h3 {
  //    margin: -24px 0 8px 0;
  //  }
  //}

  .description {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0,0,0,.45);
    margin-bottom: 24px;
    display: block;
  }
}
