@import "src/resource/style/variables";
@import "src/resource/font/Roboto/stylesheet";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Arial', serif;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html, body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

#root {
  min-height: 100vh;
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .ant-app {
    width: 100%;
  }

  > * {
    max-width: 2560px;
    box-shadow: 0 0 50px #eee;
  }
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-input-prefix {
  svg {
    color: $neutral-secondary;
  }
}

.ant-form-item-label {
  font-weight: 600;
}

h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.ant-row .ant-form-item-label {
  font-weight: normal;

  label {
    display: block;
    line-height: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ant-form-item-explain {
  &:first-letter {
    text-transform: uppercase;
  }
}

.ant-select {
  .ant-select-arrow {
    inset-inline-end: 9px;
  }
}

.clip,
.ant-form-item-explain-error {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncateDefault {
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: unset;
  overflow: hidden;
}

.truncate {
  @extend .truncateDefault;
  -webkit-line-clamp: 2;
}

.truncate3 {
  @extend .truncateDefault;
  -webkit-line-clamp: 3;
}

.no-space-form-item {
  margin-bottom: 0;
  min-height: 0 !important;
  & div {
    min-height: 0 !important;
  }
}

.ant-form-item .ant-form-item {
  margin-bottom: 0;
}

form > .ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-tag {
  text-transform: capitalize;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover a {
  color: #246060;
}

.ant-collapse-extra {
  display: flex;
  flex-wrap: nowrap;
}

.ant-message-custom-content {
  text-align: left;
}

.anticon-close-circle {
  cursor: pointer;
}

.no-space-form-item {
  margin-bottom: 0;
  min-height: 0 !important;
  & div {
    min-height: 0 !important;
  }
}

.hidden-form-item {
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

.text-secondary {
  color: rgba(0, 0, 0, 0.45);
}

.addon-sm-padding {
  .ant-input-number-group-addon {
    padding: 0 3px !important;
  }
}

.content-container {
  padding: 6px 24px 24px;
}

/** Form styles */
$label-max-width: 200px; // 180px
$item-max-width: 1000px;

.form-customized-cols {
  padding: 64px 40px;
  position: relative;

  &.miniTopPadding {
    padding: 24px; // 24px 40px;
  }

  //:global {
  .ant-form-item {
    max-width: $item-max-width;
  }

  .ant-form-item-label {
    max-width: $label-max-width;
    font-weight: normal;
  }
  //}

  *:not(:first-child) {
    > h3 {
      margin: -24px 0 8px 0;
    }
  }

  .description {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0,0,0,.45);
    margin-bottom: 24px;
    display: block;
  }

  .form-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
    //max-width: $item-max-width;
  }

  &.order-form-rewrites {
    :global {
      .ant-form-item .ant-form-item-row {
        max-width: 1200px;
      }

      .ant-input-number {
        width: 100%;
      }

      .ant-form-item-control-input {
        width: calc(100% - 128px);
      }

      .ant-form-item-extra {
        position: absolute;
        right: 0;
        top: 0;
      }

      .ant-picker {
        width: 100%;
      }

      .ant-input-number-affix-wrapper {
        width: 100%;
      }
    }
  }
}

.spin-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.4);
}

.form-hidden-after {
  label {
    &:after {
      content: '' !important;
    }
  }
}

.warning-icon {
  font-size: 20px;
  color: #FF4D4F;
}

.anticon.anticon-question-circle.ant-form-item-tooltip{
  margin-top: 8px;
}

.hidden-element {
  //display: none !important;
  visibility: hidden !important;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.bordered-container {
  border: solid 1px rgba(0,0,0,.15);
  border-radius: 6px;
  padding: 24px;
  //overflow: hidden;
}

.ant-segmented .ant-segmented-item-label {
  display: flex;
  align-items: center;
  height: 40px !important;
}

.no-padding-form-item {
  .ant-form-item-label, &.ant-form-item-label {
    padding: 0 !important;
  }
}

.minimized-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 2px;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 8px 2px;
      }
    }
  }

  p {
    margin: 0;
  }
  ul {
    padding-inline-start: 20px;
  }
}
