@import "src/resource/style/variables";

:global {
  .ant-form-item-has-error {
    .alternative-phone-input {
      border-color: $error-color;
    }
  }
}

.inputBlock {
  border: 1px solid $border-color;
  border-radius: 8px;
  transition: all 0.3s;
  line-height: 1.5;
  padding: 2px 12px;
  font-size: 14px;
  width: 100%;

  &.small {
    height: 24px;
  }
  &.middle {
    height: 32px;
  }
  &.large {
    height: 40px;
    font-size: 16px;
    padding: 4px 12px;
  }

  &:has(input:disabled) {
    border-color: $border-color;
  }

  &:hover {
    border-color: color-mix(in srgb, $primary-color 70%, transparent);
    border-right-width: 1px;
  }

  &:has(input:focus) {
    border-color: color-mix(in srgb, $primary-color 70%, transparent);
    border-right-width: 1px;
    outline: 0;
  }

  input {
    height: 100%;
    outline: none;
    border-radius: 4px;
    border: none;
    border-left: 1px solid $border-color;
    border-start-start-radius: 0;
    border-end-start-radius: 0;

    transition: all 0.3s;

    &[disabled] {
      pointer-events: none;
      color: var(--color-text-primary);
      background-color: var(--color-page-background);
    }

    &:hover {}
    &:focus {}

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }

  }
}
