@import "src/index";

.tracking {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: minmax(255px, 2fr) minmax(255px, 334px); // calc(50% - 12px) calc(50% - 12px);

  .trackingShipment {
    @extend .bordered-container;
    overflow: hidden;
  }

  .shipmentManagement {
    @extend .bordered-container;

    height: min-content;
  }

  h2 {
    margin: 0 0 24px;
  }
}
