@import 'src/resource/style/variables';

.layout {
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: $bg-color-content;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    padding: 0 8px;

    .logoSvg {
      width: 100%;
      max-width: 162px;
      height: 36px;
      object-fit: contain;
    }

    &.collapsed {
      .logoSvg {
        height: 32px;
      }
    }
  }

  .menu {
    height: calc(100% - 64px);
    overflow: hidden;
  }

  .slider {
    position: relative;
    z-index: 3;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #fff;
    box-shadow: inset -1px 0px 0px #edf0f3;

    &.absolute {
      position: absolute;
    }

    .btn {
      position: absolute;
      right: -12px;
      top: 40px;
      z-index: 10;
      width: 24px;
      height: 24px;
      background: #ffffff;
      border: solid 1px rgba(0,0,0,.06);
      transition: 0.1s;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #eee;
      }

      :global {
        svg {
          font-size: 14px;
          color: rgba(0,0,0,.45);
        }
      }
    }
  }
}

.header {
  background: rgba(0,0,0,0);
  padding: 0;

  &.headerLogo {
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: auto 1fr;

    .logo {
      width: 224px;
      border-right: solid 1px #edf0f3;

      &.tablet {
        width: 175px;
      }
    }
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 16px 24px;
    padding: 0;
    font-weight: 500;
  }
}

.wrapper {
  position: relative;
  background-color: $bg-color-content;

  &.tablet {
    margin-left: 64px;
  }

  .scroll {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 64px);
    overflow: auto;

    .content {
      position: relative;
      box-sizing: border-box;
      min-width: calc(100% - 24px * 2);
      margin: 0 24px 24px 24px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      > * {
        position: relative;
        width: 100%;

        &:global {
          &:not(.transparent) {
            background: #ffffff;
            border-radius: 8px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      &.transparent {
        background: transparent;
      }

      &.minWidth {
        min-width: 920px;
      }
    }
  }
}
