.defaultServices{
  padding: 0 25px;
  .defaultServicesHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3{
      font-weight: 500;
      font-size: 16px;
    }
  }
  .info{
    display: flex;
    & > *:not(:first-child) {
      padding-left: 24px;
    }
    margin-bottom: 24px;
    .items{
      width: 310px;
      .item{
        height: 24px;
        display: flex;
        align-items: center;
        span{
          margin-left: 8px;
        }
      }
    }
  }
}