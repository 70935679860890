.summaryBox {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  background-color: rgba(0, 0, 0, 0.02);
}
.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  gap: 4px;
}
.total {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
}
