.alert {
  & svg {
   font-size: 40px;
  }
}

.description {
  p {
    margin: 0;
  }
}
