.footer, .flexAlignEnd {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}

.footer {
  width: 100%;
}
