@import 'src/resource/style/variables';

.menu {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;

  :global {
    .ant-menu {
      width: 100%;
      max-width: 100%;

      .ant-menu-item-selected,
      .ant-menu-item:not(.ant-menu-item-selected):active {
        color: $primary-color !important;
        background: $primary-background !important;
      }

      .ant-menu-submenu {
        margin: 0;
        padding: 0;
      }

      .ant-menu-item {
        max-width: 100%;
        text-align: left;
      }

      &.ant-menu-inline-collapsed {
        .ant-menu-title-content {
          display: none;
        }

        .ant-menu-item {
          padding-inline: 0;
          text-align: center;
        }
      }

      .ant-menu-item,
      .ant-menu-submenu {
        * {
          transition: color 0s !important;
        }

        &.ant-menu-item-selected,
        &.ant-menu-item-active,
        &.ant-menu-submenu-selected svg,
        &.ant-menu-submenu-open svg {
          color: $primary-color !important;
        }

        &::after,
        &:hover::after {
          border-bottom-width: 0;
        }
      }
    }
  }
}
