@import "src/index";

.document {
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "a a"
    "b c"
    "b d";
  grid-template-columns: 1fr 1fr;

  > * {
    @extend .bordered-container;
    overflow: hidden;
  }

  .header {
    margin-bottom: 8px;

    span {
      color: rgba(0,0,0,.45);
    }
  }

  :global {
    .ant-form-item-control-input {
      width: 100%;
    }

    .ant-row,
    .ant-col,
    .ant-form-item,
    .ant-upload-wrapper {
      min-width: 100%;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .newGenerateDocument {
    b {
      display: block;
      padding: 16px 0 8px;
    }

    a {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      width: max-content;

      span {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        padding: 0;
      }

      svg {
        font-size: 18px;
        color: rgba(0,0,0,.45);
      }
    }
  }

  .flexNoWrap {
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    :global {
      .ant-upload-select {
        margin-left: 122px;
      }
    }

    .generate {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .marginBottomNone {
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .size {
    margin-left: 24px;
    padding: 8px 0;

    > * {
      &:last-child {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .emergencyPhone {
    text-align: left;

    & label {
      text-align: left;
    }
  }

  .transportDocuments {
    grid-area: a;
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    grid-gap: 24px;
  }

  .proformaInvoice {
    grid-area: b;
  }

  .additionalDocuments {
    grid-area: c;
  }

  .productDocuments {
    grid-area: d;
  }
}

.list {
  display: inline-block;

  a {
    display: block;
  }
}
