.collapse {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  svg {
    color: rgba(0,0,0,.45);
  }

  :global {
    .ant-collapse-item:last-child {
      border-radius: 0;
    }

    .ant-collapse-expand-icon {
      line-height: 32px;
      min-height: 32px;
    }
  }
}

.label {
  display: grid;
  grid-template-columns: 68px 164px 160px 150px auto 97px;
  gap: 4px;
  line-height: 32px;

  .x {
    width: 40px;
  }

  .alignRight {
    text-align: end;
  }

  .end {
    min-width: 70px;
    display: flex;
    justify-content: flex-end;
    margin-right: 24px;
  }
}

.create,
.calc {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(0, 0, 0, .02);
  padding: 12px 16px;
}

.create {
  justify-content: flex-end;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }
}

.calc {
  border: 1px solid #d9d9d9;
  border-radius: 0 0 8px 8px;

  .totalPackages {
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    column-gap: 24px; //gap: 24px;

    > * {
      white-space: nowrap;
      text-align: right;

      b {
        width: auto;
        display: inline !important;
      }
    }
  }
}

.empty {
  border-radius: 8px;
}

.viewContent {
  display: grid;
  grid-template-columns: 92px 147px auto;

  > *:first-child {
    padding-left: 24px;
    margin-top: 0;
  }

  > *:last-child {
    > * {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > * {
        padding: 12px 16px;
      }

      &:first-child > * {
        padding-top: 0;
      }

      &:last-child > * {
        padding-bottom: 0;
      }

      &:not(:last-child) {
        border-bottom: solid 1px rgba(0,0,0,.06);
      }
    }
  }

  span {
    color: rgba(0,0,0,.65);
  }

  a {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    span {
      padding-right: 6px;
      font-size: 18px;
    }
  }
}
