.invoiceGrid {
    display: grid;
    // grid-gap: 20px;
    padding: 10px 25px;
    font-size: 16px;
  }

  .invoiceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoiceHeader h2 {
    font-size: 24px;
    margin: 0;
  }

  .invoiceHeader img {
    max-width: 179px;
  }

  .invoiceInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .column {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 21px;
    div{
      display: flex;
      strong{
        width: 140px;
      }
      span{
        width: 300px;
      }
    }
  }

  strong {
    display: block;
    font-weight: bold;

  }

.tableRow{
  display: flex;
  align-items: center;
  height: 43px;
  font-size: 16px;
}
.tableCol{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dividerStyle{
  width: 900px;
  border-block-start: 1px solid black;
  margin: 0px -17px;
}
.dividerStyleRow{
  width: 463px;
  margin: 0px -17px;
  border-block-start: 1px solid rgba(146, 146, 146, 0.711);
}

.invoiceFooter{
  margin-top: 270px;
  .footerInfo{
    display: flex;
    justify-content: center;
    font-size: 14px;
    span{
      margin: 0 10px 0 5px;
    }
  }
  .footerActions{
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
