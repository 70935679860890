.wrapper,
.info,
.box {
  display: grid;
  grid-gap: 16px;
}

.wrapper {
  padding: 24px;
  grid-template-columns: 1fr;

  .info {
    grid-template-areas:
      "a b c"
      "d d e"
      "f f e";
    grid-template-columns: 1fr 1fr 1fr;

    &.clientGridAreas {
      grid-template-areas:
      "a a c"
      "d d e"
      "f f e";
    }
  }

  .orderGeneral {
    grid-area: a;
  }

  .company { // customer
    grid-area: b;
  }

  .invoicesOffers {
    grid-area: c;

    overflow: auto;
    max-height: 300px;

    padding-top: 0 !important;
    .header {
      position: sticky;
      top: 0;
      background-color: white;
      padding-top: 24px;
      z-index: 2;
      height: auto;
    }
  }

  .orderType {
    grid-area: d;
  }

  .deliveryStatus {
    grid-area: e;
  }

  .deliveryInfo {
    grid-area: f;
  }

  .box {
    grid-template-columns: 1fr 1fr;
  }

  .company,
  .orderGeneral,
  .invoicesOffers,
  .orderType,
  .deliveryInfo,
  .deliveryStatus,
  .shipper,
  .importer,
  .products,
  .documents {
    border-radius: 6px;
    border: solid 1px rgba(0,0,0,.15);
    padding: 24px;
  }

  .header {
    display: flex;
    flex-wrap: nowrap;
    height: 32px;
    line-height: 32px;
    justify-content: space-between;
    align-items: center;

    > div {
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    h2 {
      display: block;
      margin: 0;
    }
  }

  .content {
    position: relative;
  }

  .row {
    margin-top: 12px;
    flex-wrap: wrap;

    > *:first-child:not(:last-child) {
      border-bottom: none;
    }

    > a {
      display: block;
      max-width: 190px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    b {
      display: block;
      width: 100%;
    }

    .col2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4px;

      > * {
        display: block;

        > * {
          display: block;
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .col3 {
      @extend .col2;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .documents {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .title {
        padding: 12px 0 8px;
      }

      .list {
        padding: 12px 0 24px;

        a {
          display: flex;
          align-items: center;
          height: 22px;
          line-height: 22px;
          width: max-content;

          span {
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            padding: 0;
          }

          svg {
            font-size: 18px;
            color: rgba(0,0,0,.45);
          }
        }
      }
    }
  }
}
