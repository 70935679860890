.absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 30px;
}

.withBg {
  z-index: 100;
  background-color: rgba(255,255,255,.4);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  //align-items: center;
  width: 100%;
  height: 100%;

  > span {
    top: min(50%, 260px);
  }
}
