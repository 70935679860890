@import "src/resource/style/variables";

$bg-color-gray: rgba(0, 0, 0, 0.02);

.container {
  display: flex;
  flex-wrap: wrap;
  //gap: 16px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.boxFullWidth {
  width: 100%;
  display: flex;
}

.hidden {
  display: none;
}

.inputNumber {
  :global {
    .ant-input-number-prefix {
      color: rgba(0, 0, 0, 0.45);
      margin-right: 8px;
    }

    .ant-select-selector {
      padding: 0;
    }
    .ant-form-item-control-input {
      min-height: unset;
      min-width: 72px;
    }
  }

  &.withSelectAddon {
    :global {
      .ant-input-number-group-addon {
        padding: 0;
      }
    }
  }
}

.noMultiplierValue {
  .title {
    white-space: nowrap;
    padding-right: 4px;
    text {
      text-transform: uppercase;
    }
  }
  padding: 5px 6px;
  border-radius: 8px;

  border: 1px solid #d9d9d9;
  background: rgba(0, 0, 0, 0.02);
}

.regularServices, .services {
  border: solid 1px $border-color;
  border-radius: 6px;
}

.regularServices, .services, .additionalItems {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.regularServices {
  background-color: $bg-color-gray;

  .regularServiceRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    padding: 8px 24px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}

.services {

  .serviceRow {
    display: grid;
    grid-template-columns: 1fr 32px;
    justify-content: center;
    gap: 8px;
    padding: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }

  .fieldsContainer {
    .row {
      display: flex;
    }
  }

  .deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.actions {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.additionalItems {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 0;

  .row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}

.footer {
  background-color: $bg-color-gray;
  border-radius: 6px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .row {
    text-align: right;
  }
}
