@import "src/index";
@import "src/resource/style/variables";

.paperwork {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: minmax(255px, 2fr) minmax(255px, 334px); // 2fr minmax(255px, 1fr); // 316px

  .title {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .leftContent {
    &.selected {
      max-width: 760px;
    }
  }

  .rightContent {
    @extend .bordered-container;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section {
      h3 { margin: unset; }

      .list {
        .listItem {
          display: flex;
          justify-content: space-between;
          gap: 4px;
          white-space: nowrap;
          cursor: pointer;

          padding: 2px 8px;
          border-radius: 6px;
          transition: .2s all;

          &.active {
            background-color: $primary-background;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

.divider {
  :global {
    .ant-divider-inner-text {
      padding-inline: 0;
      padding-right: 1em;
    }
  }
  &:before {
    width: 0 !important;
  }
}

.col2 {
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto;
}
